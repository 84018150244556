export enum AttendanceConfirmationChannel {
  MY_SHIFT_PAGE = "MyShiftsPage",
  FACILITY_CHAT = "FacilityChat",
}
export const ATTENDANCE_CONFIRMATION_ETA_BOUNDS_IN_MINUTES = 75;

export enum ConfirmAttendanceResponseCode {
  REPLACEMENT_FOUND = "REPLACEMENT_FOUND",
  SHIFT_NOT_ELIGIBLE_FOR_ETA_UPDATES = "SHIFT_NOT_ELIGIBLE_FOR_ETA_UPDATES",
}
